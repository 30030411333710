import { CheckOutlined, InfoCircleOutlined, InfoOutlined, LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import axiosConfig from "config/axios";
import { parse } from "fast-qs";
import requestErrorHandler from "utils/requestErrorHandler";
import styles from "./buildmate.module.css";
import useGodumpApi from "hooks/useGodumpApi";
import { useLocation } from "react-router";

// import axiosConfig from "config/axios";

export default function VerifyBuildMatePayment() {
  const { search } = useLocation();

  const { createodumpOrder } = useGodumpApi();

  const { reference } = parse(search) as {
    reference: string;
  };
  const [token, setToken] = useState<string>("");
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  // https://staging-api.materialspro.ng/api/v1/order/${reference}
  const confirmPayment = async () => {
    setIsConfirming(true);
    await axiosConfig
      .post(`homeowner/verify-payment`, { reference }, { headers: { Authorization: "Bearer " + token } })
      .then(async (res) => {
        const { data } = await axiosConfig.get(`homeowner/get-order-by-payment-reference?reference=${reference}`);
        const deliveredByGodump = data?.order?.order_items.find((item: any) => item?.deliverdBygodump);
        return { id: data.order.id, deliveredByGodump };
      })
      .then(async (res) => {
        if (res.deliveredByGodump !== null) {
          await createodumpOrder(res.id);
          setIsSuccessful(true);
        } else {
          setIsSuccessful(true);
        }
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsConfirming(false));
  };

  let runOnce = true;
  useEffect(() => {
    if (runOnce === true) {
      runOnce = false;
      confirmPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _token: string = localStorage.getItem("token")?.toString()!;
    setToken(_token);
  }, []);

  if (isConfirming) {
    return (
      <div>
        <div className={styles.payment}>
          <div className={styles.card}>
            <span data-loading className={styles.icon}>
              {<LoadingOutlined />}
            </span>
            <h1>Verifying your payment</h1>
            <p>Please wait while we verify your payment</p>
          </div>
        </div>
      </div>
    );
  }
  if (isSuccessful) {
    return (
      <div>
        <div className={styles.payment}>
          <div className={styles.card}>
            <span data-success className={styles.icon}>
              <CheckOutlined />
            </span>
            <h1>Payment Successful!</h1>
            <p>We have received your payment and we will process your order shortly</p>
            <a href="#success" className={styles["proceed-btn"]}>
              Proceed
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={styles.payment}>
        <div className={styles.card}>
          <span data-error className={styles.icon}>
            <InfoCircleOutlined />
          </span>
          <h1>Unable to verify your payment</h1>
          <p>
            Please contact Buildmate at <a href="mailto:info@materialspro.ng">info@materialspro.ng</a> or by phone at{" "}
            <a href="tel:+23409165407978">09165407978 </a> for further assistance or inquiries.,
          </p>
          <a href="#fail" className={styles["back-btn"]}>
            Go back
          </a>
        </div>
      </div>
    </div>
  );
}
