import { useEffect, useState } from "react";
import requestErrorHandler from "utils/requestErrorHandler";
import { parse } from "fast-qs";
import axiosConfig from "config/axios";
import { useLocation } from "react-router";

const usePayment = () => {
  const [paystackUrl, setPaystackUrl] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { search } = useLocation();

  const { orderId, token } = parse(search) as {
    orderId: string;
    token: string;
  };

  const handlePayNow = async () => {
    setProcessing(true);
    if (!orderId || !/^[a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}$/i.test(orderId)) {
      setError("Invalid order id");
      setProcessing(false);
      return;
    }
    if (!token) {
      setError("Invalid token");
      setProcessing(false);
      return;
    }
    try {
      const {
        data: {
          data: { data },
        },
      } = await axiosConfig.post(`homeowner/pay`, { orderId }, { headers: { Authorization: "Bearer " + token } });
      setPaystackUrl(data?.authorization_url);
    } catch (error: any) {
      requestErrorHandler(error);

      error.response &&
        setError(
          error.response.data?.error || "We are unable to process your payment at the moment. Please try again later."
        );
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (!paystackUrl) return;
    location.replace(paystackUrl);
    // window.location.href = `${paystackUrl}`;
  }, [paystackUrl]);

  return {
    handlePayNow,
    processing,
    error,
  };
};

export default usePayment;
