import React, { useEffect } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import bmLogo from "assets/logo/bm-logo-dark.png";
import styles from "./buildmate.module.css";
import usePayment from "hooks/usePayment";

function BuildMatePay() {
  const { handlePayNow, error } = usePayment();

  useEffect(() => {
    handlePayNow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        color: "#EB5017",
        textAlign: "center",
      }}
      className={styles.payment}>
      <img
        src={bmLogo}
        alt=""
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
        width={160}
        height={160}
      />
      {error ? (
        <p>{error}</p>
      ) : (
        <>
          <p
            style={{
              marginBottom: 20,
            }}>
            Loading payment gateway
          </p>
          <span style={{ fontSize: 24 }}>
            <LoadingOutlined />
          </span>
        </>
      )}
    </div>
  );
}

export default BuildMatePay;
